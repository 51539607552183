<template>
  <div class="phone-next">
    <h1 class="count">
      {{ chargeFee }}
      <span class="unit">{{ $tc('common.unit', chargeFee) }}</span>
    </h1>
    <h2 class="title">
      {{ $t('recharge.phoneTitle') }}
    </h2>
    <div class="account">
      <MiInput
        :placeholder="$t('recharge.cardPlaceholder')"
        v-model="cardNum"
        @focus="focusHandle"
        @blur="blurhandle"
      />
      <MiInput
        class="password-input"
        :placeholder="$t('recharge.pwdPlaceholder')"
        v-model="cardPwd"
        @focus="focusHandle"
        @blur="blurhandle"
      />
    </div>
    <div
      class="mi-button phone-btn"
      :class="{ disabled, visibility: fixed }"
      @click="opacityHandler"
    >
      立即充值
    </div>
    <MiFooter
      v-show="fixed"
      :disabled="disabled"
      :content="$t('common.rechargeBtn')"
      @click="recharge"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { MiInput, MiFooter } from '@/components'

export default {
  components: {
    MiInput,
    MiFooter
  },
  data() {
    const { carrier, chargeFee } = this.$route.query

    return {
      carrier,
      chargeFee,
      cardNum: '',
      cardPwd: '',
      fixed: true,
      visibility: false
    }
  },
  computed: {
    ...mapState({
      userId: state => state.about.userInfo?.userId
    }),
    disabled() {
      const { cardNum, cardPwd } = this

      return !(cardNum && cardPwd)
    }
  },
  methods: {
    opacityHandler() {
      const { visibility, disabled } = this

      if (!visibility || disabled) return
      this.visibility = false
      this.recharge()
    },
    async recharge() {
      try {
        const { userId, cardPwd, carrier, cardNum, chargeFee } = this

        await this.$messageBox({
          title: this.$t('recharge.picker.title'),
          content: this.$t('recharge.picker.desc'),
          cancel: this.$t('common.cancel'),
          confirm: this.$t('common.confirm')
        })

        this.$http.phoneRecharge({
          userId,
          carrier,
          cardNum,
          cardPwd,
          chargeFee
        })
      } catch {
        console.log('cancel')
      }
    },
    focusHandle() {
      this.$nextTick(() => {
        this.fixed = false
        this.visibility = true
      })
    },
    blurhandle() {
      this.fixed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-next {
  height: 100%;
  padding: 40px 36px 90px;
  .count {
    font-weight: bold;
    font-size: 110px;
    text-align: center;
    font-family: Mitype;
    .unit {
      font-weight: normal;
      font-size: 48px;
    }
  }
  .password-input {
    margin-top: 20px;
  }
  .phone-btn {
    margin-top: 90px;
  }
  .visibility {
    opacity: 0;
  }
}
.title {
  padding-left: 44px;
  color: #8c93b0;
  font-size: 36px;
}
.account {
  margin-top: 34px;
}
</style>
